//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n';
export default {
  name: 'app-home-header',

  data() {
    return {
      // language: this.$route.params.lang,
      changeLanguageAr: false,
      changeLanguageEn: false,
      headerImgAnimation: '',
    };
  },
  watch: {
    $route(to) {
      // debugger
      if (to.params.lang == 'ar') {
        // this.language = 'ar'
        // this.changeLanguageAr = true
        this.changeLanguageEn = false;
      } else {
        // this.language = 'en'
        this.changeLanguageEn = true;
        // this.changeLanguageAr = false
      }
    },
  },
  computed: {
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar';
    },

    imageHeaderSrc() {
      if (this.language == 'en') {
        return 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fmobile-app-1.webp?alt=media&token=ed329795-ba78-4efb-9b8b-d083e122f8d8';
      } else {
        return 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fmobile-app-ar-1.webp?alt=media&token=a1d934bb-baf3-4a3d-a9e4-a35ee74a0b72';
      }
    },
    // imageHeaderSrc(){
    //   // setTimeout(() => {
    //     if(!this.isRTL){
    //       setTimeout(() => {
    //         return 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fmobile-app-1.webp?alt=media&token=ed329795-ba78-4efb-9b8b-d083e122f8d8'
    //       },1000)
    //     }
    //     else{
    //       setTimeout(() => {
    //         return 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fmobile-app-ar-1.webp?alt=media&token=a1d934bb-baf3-4a3d-a9e4-a35ee74a0b72'
    //     },1000)
    //   }
    // :src="!isRTL?'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fmobile-app-1.webp?alt=media&token=ed329795-ba78-4efb-9b8b-d083e122f8d8':'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fmobile-app-ar-1.webp?alt=media&token=a1d934bb-baf3-4a3d-a9e4-a35ee74a0b72'"

    // }
  },
  methods: {
    onImgLoadEn() {
      // headerImgAnimation
      // if(this.changeLanguageEn){
      //   debugger
      this.headerImgAnimation =
        'animate__animated animate__bounceInRight';
      // }
      // else{

      // }
    },
    onImgLoadAr() {
      this.headerImgAnimation =
        'animate__animated animate__bounceInLeft';
    },

    displayImgAr() {
      // if(!)
      // setTimeout(() =>{
      if (!this.isRTL) {
        return true;
      }
      return false;
      // },1000)
    },
  },
};
