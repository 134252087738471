//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n'
export default {
  name: 'app-home-about',

  data() {
    return {
    };
  },
  computed: {
    isRTL() {
      return i18n.locale == 'ar'
      // return localStorage.getItem('language') == 'ar'
    },
  },
  methods: {
    // scrollHandler (scroll) {
    //   const ele = document.getElementById('about')

    //   // {
    //   //   position: 56, // pixels from top
    //   //   direction: 'down', // 'down' or 'up'
    //   //   directionChanged: false, // has direction changed since this handler was called?
    //   //   inflexionPosition: 56 // last scroll position where user changed scroll direction
    //   // }
    // }
  }
};
