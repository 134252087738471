//
//
//
//
//
//
//
//
//

// import { i18n } from '@/i18n';
import HomeHeader from '@/modules/home/components/home-header.vue';
import HomeAbout from '@/modules/home/components/home-about.vue';
import HomeFeatures from '@/modules/home/components/home-features.vue';
import HomeFooter from '@/modules/home/components/home-footer.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'app-home-page',

  components: {
    [HomeHeader.name]: HomeHeader,
    [HomeAbout.name]: HomeAbout,
    [HomeFeatures.name]: HomeFeatures,
    [HomeFooter.name]: HomeFooter,
  },

  created() {
    if (this.isMobile) {
      this.collapseMenu();
    }
  },

  data() {
    return {

    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
    })
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
  }
};
