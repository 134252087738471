//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
// import { getLanguageCode } from '@/i18n';
import { scroll } from 'quasar';
import i18n from '@/vueI18n'
const { getScrollTarget, setScrollPosition } = scroll;

export default {
  name: 'app-home-footer',

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      runprof_url: 'home/runprof_url',
      facebook_url: 'home/facebook_url',
      instagram_url: 'home/instagram_url',
      twitter_url: 'home/twitter_url',
      whatsapp_url: 'home/whatsapp_url',
      drugdeal_email: 'home/drugdeal_email',
      isMobile: 'layout/isMobile',
    }),
    isRTL() {
      return i18n.locale == 'ar'
      // return localStorage.getItem('language') == 'ar'
    },
    currentYear() {
      const d = new Date()
      return d.getFullYear()
    },
  },

  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    goToPrivacyPolicy(){
      this.$router.push('/privacy-policy')
    },
    handleScroll(eleId) {
      // debugger
      const ele = document.getElementById(eleId); // You need to get your element here
      const target = getScrollTarget(ele);
      // const offset = ele.offsetTop - ele.scrollHeight
      const offset = ele.offsetTop;
      // const offset = 50
      const duration = 1000;
      setScrollPosition(target, offset, duration);
      // this.navigation(eleId);
    },
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
